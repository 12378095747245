<template>
  <div id="resetPassword">
    <a-layout class="layout-style">
      <a-layout-header style="background: #F8F9FB">
        <a-row justify="end" align="middle">
          <global-outlined class="ic-global" />
          <a-dropdown :trigger="['click']">
            <a class="main-text-color" @click.prevent>
              {{ $i18n.locale === 'en' ? 'English' : '中文' }}
              <DownOutlined />
            </a>
            <template #overlay>
              <a-menu @click="languageChange">
                <a-menu-item key="zh" :disabled="$i18n.locale === 'zh'">
                  <a>中文</a>
                </a-menu-item>
                <a-menu-item key="en" :disabled="$i18n.locale === 'en'">
                  <a>English</a>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-row>
      </a-layout-header>
      <a-layout-content>
        <a-row align="middle">
          <img :src="themeData.darkLogoList" class="logo">
        </a-row>
        <a-row v-if="!isInvalid" class="register-body flex-align">
          <div class="register-img">
            <img :src="themeData.bgList">
          </div>
          <div class="card-register">
            <h1>{{ $t('pages_register_035') }}</h1>
            <div class="card-body">
              <a-form
                ref="editRef"
                layout="vertical"
                :model="editForm"
                :rules="editRules"
                @finish="handleReset"
              >
                <a-form-item name="email" :label="$t('pages_register_036')">
                  <a-input v-model:value="editForm.email" disabled />
                </a-form-item>
                <a-form-item>
                  <div style="font-weight: 500">{{ $t('pages_user_066') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_user_067') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_user_068') }}</div>
                  <div style="margin-top: 8px">{{ $t('pages_user_069') }}</div>
                </a-form-item>
                <a-form-item name="pwd" :label="$t('pages_register_016')">
                  <a-input-password
                    v-model:value="editForm.pwd"
                    :placeholder="$t('common_text_006') + $t('pages_register_016')"
                  />
                </a-form-item>
                <a-form-item name="confirmPwd" :label="$t('pages_user_059')">
                  <a-input-password
                    v-model:value="editForm.confirmPwd"
                    :placeholder="$t('common_text_006') + $t('pages_user_059')"
                  />
                </a-form-item>
                <a-form-item style="margin-top: 48px">
                  <a-button
                    style="width: 100%"
                    shape="round"
                    type="primary"
                    html-type="submit"
                    :loading="loading"
                  >{{ $t('pages_register_035') }}</a-button>
                </a-form-item>
                <a-form-item style="text-align: center; margin-top: -18px;  font-size: 12px">
                  <span style="color: #999">{{ $t('common_text_003') }}</span>
                  <span class="main-text-color" style="cursor: pointer; margin-left: 5px" @click="$router.push('/login')">{{ $t('pages_login_005') }}</span>
                </a-form-item>
              </a-form>
            </div>
          </div>
        </a-row>
        <div v-else class="link-invalid">
          <img src="~@/assets/images/link-invalid.png">
          <div>{{ $t('pages_register_015') }}</div>
        </div>
      </a-layout-content>
    </a-layout>
    <div class="keep-record">
      <div v-if="themeData.address">{{ themeData.address }}</div>
      <a target="_blank" href="https://beian.miit.gov.cn">{{ themeData.copyRight }}</a>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, inject } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { _login } from '@/api'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
export default {
  name: 'ResetPassword',
  setup(props) {
    const validPasswordFormat = (rule, value) => {
      const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/
      if (!value) return Promise.reject(i18n.global.t(rule.field === 'pwd' ? 'pages_register_016' : 'pages_user_059') + i18n.global.t('common_text_042'))
      if (!pattern.test(value)) return Promise.reject(i18n.global.t('pages_user_060'))
      if (rule.field === 'confirmPwd' && value !== state.editForm.pwd) return Promise.reject(i18n.global.t('pages_user_061'))
      return Promise.resolve()
    }
    const editRef = ref(null)
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const state = reactive({
      themeData: inject('$themeData'),
      token: '',
      loading: false,
      isInvalid: false,
      editForm: {
        pwd: '',
        confirmPwd: ''
      },
      editRules: {
        pwd: [{ required: true, validator: validPasswordFormat }],
        confirmPwd: [{ required: true, validator: validPasswordFormat }]
      }
    })

    const languageChange = async (e) => {
      localStorage.setItem('locale-language', e.key)
      await store.dispatch('getLanguage')
      location.reload()
    }

    const handleReset = async () => {
      state.loading = true
      const data = Object.assign({}, state.editForm, {
        key: state.token
      })
      const res = await _login.resetUserPwd(data)
      state.loading = false
      if (res.data.succ) {
        message.success(i18n.global.t('common_text_048'))
        router.replace('/login')
      } else {
        message.error(res.data.resMsg)
      }
    }

    const loadEmailInfo = async (secretKey) => {
      const res = await _login.getEmailInfoByToken({ secretKey })
      if (res.data.succ) state.editForm.email = res.data.data
      else state.isInvalid = true
    }

    const init = () => {
      state.token = route.query.token
      loadEmailInfo(state.token)
    }

    init()

    return {
      editRef,
      handleReset,
      languageChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#resetPassword {
  .scrollbar-none;
  background: #F8F9FB;
  height: 100vh;
  overflow-y: scroll;
  .ic-global {
    color: @main-color;
  };
  .logo {
    width: 180px;
  };
  .register-body {
    margin-top: 54px;
    box-shadow: 0px 2px 9px 6px rgba(245, 245, 245, 0.5);
    border-radius: 8px;
    background: #fff;
    .register-img {
      flex: 1.5;
      overflow: hidden;
      img {
        width: 484px;
        height: 440px;
      }
    }
    .card-register {
      flex: 1;
      text-align: left;
      padding: 39px 63px 0 44px;
      .card-body {
        margin: 30px 0 0 37px;
        .send-btn {
          background: @table-th-color;
          border: none;
          color: #000;
        }
      }
    }
  }
  .link-invalid {
    margin-top: 188px;
    img {
      width: 100px;
    }
  }
  .keep-record {
    text-align: center;
    color: #9c9d9c;
    padding-top: 128px;
    a {
      color: #9c9d9c;
    }
    a:hover {
      text-decoration: underline;
    }
  }
}
#resetPassword::-webkit-scrollbar {
  display: none;
}
</style>

